<template>
	<div class="Product">
		<div class="header-row d-sm-block d-md-flex no-gutters">
			<!--      <v-col class="col-xs-12 col-md-3">-->
			<!--        <v-img class="fluid-flow-image" src="@/assets/product.png" alt="Fluid GF"/>-->
			<!--      </v-col>-->
			<v-col class="col-xs-12 offset-md-1 col-md-7 order-top">
				<div class="fluid-flow-image__container">
					<v-img class="fluid-flow-image" src="@/assets/membraneWrapLogo.png" alt="Membrane Wrap Logo"/>
				</div>
				<p>BioLab Sciences Membrane Wrap™ is a unique DUAL- LAYERED dehydrated human amnion membrane (dHAM) allograft composed primarily of a connective tissue matrix. It is minimally manipulated, preserving the properties that it exhibits in its natural state. The presence of the connective tissue matrix aids in supporting and protecting the wound.</p>
<!--				<div class="btn-container">-->
<!--          <v-btn-->
<!--              x-large-->
<!--              :color="'#00aeef' || '#fb7c34'"-->
<!--              class="play-video-button"-->
<!--              href="https://vimeo.com/633073497/be1428e81a"-->
<!--              target="_blank"-->
<!--          >-->
<!--            Play Video <i class="fas fa-play" :style="{ color: 'white', 'margin-left': '1rem' }" />-->
<!--          </v-btn>-->
<!--				</div>-->
			</v-col>
			<v-col class="col-xs-12 col-md-4 d-flex justify-center joints-img__container">
				<v-img class="joints-img" src="@/assets/WoundHealingImage.webp" alt="Wound Healing Image"/>
			</v-col>
		</div>

		<hr>

		<!--    <h3 class="spacer-title">Relevant Conditions</h3>-->
		<!--    <div class="details d-sm-block d-md-flex about-details">-->
		<!--      <v-col class="details__item col-sm-12 col-md-4">-->
		<!--        <div class="details-header">-->
		<!--          <v-icon class="text-color-green details-header__check">fan fa-check</v-icon>-->
		<!--          DEGENERATIVE JOINT DISORDERS-->
		<!--        </div>-->
		<!--        <div class="details">-->
		<!--          <ul>-->
		<!--            <li>Sports Injuries</li>-->
		<!--            <li>Osteoarthritis</li>-->
		<!--            <li>Joint Pain</li>-->
		<!--          </ul>-->
		<!--        </div>-->
		<!--      </v-col>-->
		<!--      <v-col class="details__item col-sm-12 col-md-4">-->
		<!--        <div class="details-header">-->
		<!--          <v-icon class="text-color-green details-header__check">fan fa-check</v-icon>-->
		<!--          SOFT TISSUE INJURIES-->
		<!--        </div>-->
		<!--        <div class="details">-->
		<!--          <ul>-->
		<!--            <li>Ligament and Tendon Sprains</li>-->
		<!--            <li>Muscle and Meniscus Tears</li>-->
		<!--            <li>Acute and chronic Wounds</li>-->
		<!--          </ul>-->
		<!--        </div>-->
		<!--      </v-col>-->
		<!--      <v-col class="details__item col-sm-12 col-md-4">-->
		<!--        <div class="details-header">-->
		<!--          <v-icon class="text-color-green details-header__check">fan fa-check</v-icon>-->
		<!--          INFLAMMATORY CONDITIONS-->
		<!--        </div>-->
		<!--        <div class="details">-->
		<!--          <ul>-->
		<!--            <li>Bursitis</li>-->
		<!--            <li>Tendonitis</li>-->
		<!--            <li>Fasciitis</li>-->
		<!--          </ul>-->
		<!--        </div>-->
		<!--      </v-col>-->
		<!--    </div>-->
		<div class="details d-sm-block d-md-flex flex-md-row hero hero-flat pa-0">
			<v-col class="details__item col-sm-12 col-md-6 left-side">
				<div class="details-header headline">WHAT TYPE OF CONDITIONS ARE TREATABLE WITH MEMBRANE WRAP™?</div>
        <div class=""><v-icon class="details-header__check">fan fa-check</v-icon> Diabetic Foot Ulcers</div>
        <div class=""><v-icon class="details-header__check">fan fa-check</v-icon> Venous Stasis Ulcers</div>
        <div class="details pl-6 pr-6">
					<p>The natural growth factors and cytokines so abundantly present in amniotic tissue aide in faster healing, greater pain reduction, and less scarring for the patient.</p>
				</div>
			</v-col>
			<v-col class="details__item col-sm-12 col-md-6 right-side">
				<div class="details-header headline">COVERED BY INSURANCE, INCLUDING MEDICARE</div>
				<div class="details d-flex flex-column pl-6 pr-6">
					<p>BioLab Membrane Wrap™ has been issued Q-Code: Q4205 for the following intended use:</p>
					<p>“The patient population for use of the products includes children and adults suffering from non-healing acute and chronic wounds (diabetic, venous, mixed, venous-arterial, pressure ulcers), complex and/or open surgical wounds and burns.” <br><br><small>CMS HCPCS Public Meeting, May 13, 2019</small>
					</p>
				</div>
			</v-col>
		</div>


		<div class="details d-sm-block d-md-flex flex-md-row">
			<v-col class="details__item col-sm-12 col-md-5">
				<div class="details-header d-flex flex-column headline">
					<span class="bold-me">FDA REGISTERED AS AN HCT/Ps</span>
					<!--					<span class="details-header__sub">Fluid GF™ IS UNIQUE FOR MANY REASONS:</span>-->
				</div>
				<p>BioLab Sciences Membrane Wrap™ has been audited by the FDA and registered as a compliant HCT/Ps solely regulated under section 361 of the Public Health Service Act (PHS Act) and 21 CFR Part 1271, including the requirements of minimal manipulation and homologous intended use.</p>
				<div class="details">

				</div>
				<a class="download-link"
						target="_blank"
						href="https://www.accessdata.fda.gov/scripts/cber/CFAppsPub/tiss/Index.cfm?fuseAction=fuse_DisplayDetails"
				>Click Here to View FDA Registration</a>
			</v-col>
			<v-col class="details__item col-sm-12 col-md-7">
				<v-img src="@/assets/wm_table-min.png" alt="WM Table"/>
			</v-col>
		</div>


		<div class="details d-sm-block d-md-flex flex-md-row hero hero-flat">
			<v-col class="details__item col-sm-12 col-md-3">
				<v-img src="@/assets/guarantee.png" alt="Guarantee Badge"/>
			</v-col>
			<v-col class="details__item col-sm-12 col-md-9">
				<div class="details-header headline bold-white">Reimbursement Guarantee</div>
				<div class="details text-left d-flex flex-column">
					<p>BioLab Sciences and EMS Bio offer support to your billing department in securing reimbursements. If for any
						reason the insurance denies the claim, we will go to work on your behalf.</p>
					<p>BioLab Sciences is so confident in this process that we offer a Reimbursement Guarantee that replaces the
						product used, free of charge, if you are denied reimbursement.</p>
				</div>
				<!--        <a class="download-link"-->
				<!--            target="_blank"-->
				<!--            href="https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/Reimbursement%20Guarantee%202020.pdf?alt=media&token=4fc42784-5861-4118-b643-cc2f4a8b8eef"-->
				<!--        >Download PDF</a>-->
			</v-col>
		</div>


		<div class="details d-sm-block d-md-flex flex-md-row">
			<v-col class="details__item col-sm-12 col-md-8 offset-md-2">
				<div class="details-header d-flex flex-column headline">
					<span>No Ethical Concerns</span>
					<!--          <span class="details-header__sub">There are no ethical concerns in using BioLab Fluid GF™ product to treat your patient. BioLab ensures the following:</span>-->
				</div>
				<div class="details">
					<ul class="no-checks">
						<li>
							<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
							Consenting mothers
						</li>
						<li>
							<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
							Scheduled cesarian births
						</li>
						<li>
							<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
							Fetus is untouched
						</li>
						<li>
							<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
							Rigorous health screenings
						</li>
					</ul>
				</div>
				<p class="mt-4 text-left">
					BioLab Sciences amniotic liquid allografts have been tested for potentially infectious diseases and
					terminally sterilized to ensure the safety of each liquid allograft. The donated tissue has been deemed
					acceptable for transplant and all laboratories performing these tests are registered with the FDA and
					certified to perform testing on human specimens under CLIA and 42 CFR part 493, or equivalent requirements.
				</p>
			</v-col>
			<!--      <v-col class="details__item col-sm-12 col-md-5">-->
			<!--        <v-img src="@/assets/quality.png" alt="quality img"/>-->
			<!--      </v-col>-->
		</div>


		<!--    <div class="details d-sm-block d-md-flex flex-md-row hero hero-flat">-->
		<!--      <v-col class="details__item col-sm-12 col-md-4">-->
		<!--        <v-img src="@/assets/lungs.png" alt="Reimbursement letter"/>-->
		<!--      </v-col>-->
		<!--      <v-col class="details__item col-sm-12 col-md-8">-->
		<!--        <div class="details-header headline">-->
		<!--          <span class="bold-white">Other Uses: Amnio Breathe</span>-->
		<!--        </div>-->
		<!--        <div class="details flex-column text-left">-->
		<!--          <p>BioLab Amnio Fluid GF™ has also been designed to deliver our ambient liquid allograft product as a topical directly onto a patient’s respiratory system (nasal cavity, pharynx, larynx, bronchus, and lungs).</p>-->
		<!--          <p>The natural growth factors and cytokines that are present in amniotic fluid help to regenerate soft tissue while inhibiting inflammation and scar tissue formation.</p>-->
		<!--        </div>-->
		<!--        <a class="download-link"-->
		<!--            target="_blank"-->
		<!--            href="https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/Amnio%20Breathe%20Brochure.pdf?alt=media&token=8beeb69b-c28d-4691-a2b0-c88b826d0afa"-->
		<!--        >Download PDF</a>-->
		<!--      </v-col>-->
		<!--    </div>-->


	</div>
</template>

<script>
export default {
	name: 'Product',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'BioLab Sciences Membrane Wrap™ is a unique DUAL- LAYERED dehydrated human amnion membrane used for wound care, specifically diabetic ulcers and venous stasis ulcers.',
		meta: [
			{
				name: 'description',
				content: 'BioLab Sciences Membrane Wrap™ is a unique DUAL- LAYERED dehydrated human amnion membrane (dHAM) allograft composed primarily of a connective tissue matrix. It is minimally manipulated, preserving the properties that it exhibits in its natural state. The presence of the connective tissue matrix aids in supporting and protecting the wound.'
			},
		],
		// all titles will be injected into this template
		titleTemplate: '%s'
	},
	props: {},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.play-video-button {
  color: white;
}

.fluid-flow-image__container {
	display: flex;
	justify-content: center;

	.fluid-flow-image {
		max-width: 50vw;
	}
}

.details-header {
	&__sub {
		color: black;
		font-weight: normal;
	}
}

.no-checks {
	list-style: none;
	padding-left: 0;
}

.bold-white {
	font-weight: bold;
	color: white;
}

//.headline {
//  background-color: #ffffffa3;
//}
.bold-me {
	font-weight: bold;
}

.left-side {
	padding: 2rem;
	background-color: #00aeef;
	//background-color: #fb7c34;
	height: 100%;

	.headline {
		color: white;
		font-weight: bold;
	}
}

.right-side {

	.headline {
		color: white;
		font-weight: bold;
	}
}

.download-link {
	color: #00aeef;
	//color: #fb7c34;

	&:hover {
		//color: #64bb5c;
		color: #231f20;
	}
}

.hero-flat {
	align-items: flex-start;
}

.details {
	ul {
		text-align: left;

		li:not(:first-child) {
			margin-top: .5rem;
		}
	}

	.details__item {
		.details {
			justify-content: center;
			align-items: center;
		}
	}
}

.order-top {
	padding: 2rem;

	.btn-container {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.spacer-title {
	width: 100%;
	text-align: center;
	font-size: 24px;
	color: #507DBC;
}

.joints-img {
	width: 100%;
	max-height: 350px;
	max-width: 450px;
	height: auto;

	&__container {
		display: flex;
		justify-content: flex-end;
	}
}

.order-now-button {
	color: white;
}
</style>
